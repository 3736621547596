.about-container {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10rem;
}
.content-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    height: 100%;
}
.about-container h2 {
    display: flex;
    flex-wrap: nowrap;
}
.about-container img {
    align-self: center;
    margin: 1rem;
    width: 20rem;
    height: 20rem;
}
.about-me-more{
    display: flex;
    flex-wrap: wrap;
    margin-left: 1rem;
}
.show-more {
    display: inline;
    cursor: pointer;
}

summary::marker{
    color: var(--highlight);
    cursor: pointer;
}
details summary::before{
    cursor: pointer;
    content: "show more";
    color: var(--highlight);
  }
 details[open] summary::before{
    cursor: pointer;
    content: "show less";
    color: var(--highlight);
  }




@media only screen and ( max-width: 950px){
    .about-container{
        margin: 10rem 2rem;
        height: fit-content;
    }
    .content-container {
        flex-direction: column;
        align-items: center;
    }
}