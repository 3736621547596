.sideWrapper-container{
    position: fixed;
    display:flex;
    width: 100%;
    justify-content: space-between;
    bottom: -4rem;
}
.wrapper {
    display: flex;
    flex-direction: column;
    margin: 2rem;
    height: 20rem;
}
.wrapper div {
    margin-bottom: 2rem;
}

.left-wrapper hr {
    height: 15rem;
    width: 1px;
    margin-top: 1rem;
    background-color: var(--highlight);
    border-width: 0rem;
    bottom: 0rem;
    overflow: hidden;
   

}

.left-wrapper {
    font-family: "Font Awesome 5 Brands";
    display: flex;
    flex-direction: row;
    align-items: center;
    writing-mode: vertical-rl;
    z-index: 10;
}
.left-wrapper i {
    margin: 0rem;
    padding:1rem;
}
.left-wrapper a:hover{
    transition: 0.5s ease-in-out;
    transform: translateY(-0.25rem);
    text-shadow: 0.5rem 0.5rem 0.25rem var(--shadow);
    color:var(--highlight)
}

.right-wrapper hr{
    height: 8rem;
    width: 1px;
    margin-top: 1rem;
    background-color: var(--highlight);
    border-width: 0rem;
    bottom: 0rem;
    overflow: hidden;
}
.right-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    writing-mode: vertical-lr;
    z-index: 10;
   
}
.right-wrapper a:hover{
    transition: 0.5s ease-in-out;
    transform: translateY(-0.25rem);
    text-shadow: 0.5rem 0.5rem 0.25rem var(--shadow);
    color:var(--highlight)
}
@media only screen and ( max-width: 950px){
    .sideWrapper-container{
    max-width: 100vw;
    }
    .wrapper {
        height: 15rem;
        margin: 0.5rem;
    }
    .right-wrapper hr {
        height: 2rem;
    }
}