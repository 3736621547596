.projects-container{
    margin: 2rem 0rem 5rem;
    height: 50rem;
    display: flex;
    flex-direction: column;
    align-items:  center;
    
}
.projects-container h2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
}
.carousel-container {
    display: grid;
    grid-template-rows: 34.25rem 6.25rem;
    grid-template-columns: 20rem 2rem 2rem 2rem 2rem 20rem;
    align-items: center;
    justify-items: center;
    z-index: 1;
}

#carousel {
    grid-row: 1 / 2;
    grid-column: 1 / 7;
    width: 100vw;
    height: 40rem;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transform-style: preserve-3d;
    perspective: 35rem;
    --items: 5;
    --middle: 3;
    --position: 1;
    pointer-events: none;
}
.project{
    display: flex;
    align-items: center;
    position: absolute;
    width: 90%;
    justify-content: center;
    height: 40rem;
    --r: calc(var(--position) - var(--offset));
    --abs: max(calc(var(--r) * -1), var(--r));
    transition: all 0.5s ease-in-out;
    transform: rotateY(calc(-20deg * var(--r)))
        translateX(calc(-30rem * var(--r)));
    z-index: calc(var(--position) - var(--abs) + 2);
    pointer-events: auto;
}

 
.project:nth-of-type(1) {
    --offset:1;
}
.project:nth-of-type(2) {
    --offset: 2;
  }
  .project:nth-of-type(3) {
    --offset: 3;  
  }

  .project:nth-of-type(4) {
    --offset: 4; 
  }
  input:nth-of-type(1) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }
  input:nth-of-type(1):checked ~ #carousel {
    --position: 1;
  }
  
  input:nth-of-type(2) {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
  }
  input:nth-of-type(2):checked ~ #carousel {
    --position: 2;
  }
  
  input:nth-of-type(3) {
    grid-column: 4 /5;
    grid-row: 2 / 3;
  }
  input:nth-of-type(3):checked ~ #carousel {
    --position: 3;
  }
  
  input:nth-of-type(4) {
    grid-column: 5 / 6;
    grid-row: 2 / 3;
  }
  input:nth-of-type(4):checked ~ #carousel {
    --position: 4;
  }
  .project img:hover{
    cursor: pointer;
  }
  .project a {
    z-index: 11;
    overflow: visible;
  }
  .project-content{
    display: flex;
    padding: 0rem 0.5rem;
    transform: translateX(-5rem);
    border-radius: 1rem;
    width:fit-content;
    max-width: 35rem;
    align-items: flex-end;
    flex-direction: column;
    background-color:var(--shadow);
    box-shadow: 1rem 1rem 1rem var(--shadow);
    opacity: 0.97;
    z-index: 12;
  }
  .project-content h3 {
    padding-right: 2rem;
  }
  .project-content a:hover{
    color:var(--highlight);
    transform: translateY(-0.25rem);
    text-shadow: .5rem .5rem 1rem (--shadow);
  }
 
  .details {
    font-size: small;
    display: flex;
    flex: row;
    align-items: center;
  }
  .details a {
    padding: 0rem  1rem;
  }
  .arrow{
    font-size: large;
    font-weight: bold;
  }
  .arrow:hover{
    cursor: pointer;
  }

  #left-arrow{
    padding-right: 0.5rem;
    justify-self: flex-end;
  }
  #left-arrow:hover{
    transform: translateX(-0.25rem)
  }
  #right-arrow{
    padding-left: 0.5rem;
    justify-self: flex-start;
  }
  #right-arrow:hover{
    transform: translateX(0.25rem)
  }


  @media only screen and ( max-width: 950px){
    .carousel-container{
        grid-template-columns: 5rem 2rem 2rem 2rem 2rem 5rem;
    }
    #carousel{
       
        margin: 3rem;
    }
    .project {
        align-items: center;
        flex-direction: column;
    }
    .project img {
        max-width: 100%;
    }
    .project-content {
        max-width: 90%;
        margin-top: 1rem;
        align-items: center;
        transform: translateX(0rem);    
    }
  }
