.skills-container {
    margin: 2rem 10rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}
.skills-container h2 {
        display: flex;
        flex-direction: row;
}

.skills-container i {
    font-size: 60px;
    padding: 1rem 2rem 0rem 2rem;
}
.skills-container p {
    font-size: 20px;
}

.skills-container a:hover{
    transition: 0.5s ease-in-out;
    transform: translateY(-0.25rem);
    text-shadow: 0.5rem 0.5rem 0.2rem var(--shadow);
    color:var(--highlight)
}

.skills {
    font-family: "Font Awesome 5 Brands";
    display: flex;
    height: fit-content;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 2rem;
    z-index: 1;
}

@media only screen and ( max-width: 950px){
    .skills-container{
        margin: 2rem 3rem;
    }
    .skills-container i{
        font-size:3rem;
    }
}