.App {
  text-align: center;
  background-color: var(--background);
  padding-top: 4rem;
  color: var(--text);
}


h1 {
  font-size: 60px;
}

.text-container {
  border-radius: 1rem;
  justify-self: center;
  text-align: left;
  max-width: 40%;
  max-height: 40%;
  padding: 0.75rem;
}
  

h2{
 
  margin: auto;
  margin-bottom: 2rem;
  align-items: center;
  font-size: 25px;
}

h2 hr {
  display: flex;
  flex-shrink: 1;
  margin: auto;
  margin: 1rem;
 width: 9rem;
  height: 1px;
  background-color: var(--highlight);
  border-width: 0px;
}
a {
  z-index: 10;
}

img{
  border-radius: 1rem;
  box-shadow: 1rem 1rem 1rem var(--shadow);
  max-width: 40rem;
  max-height: 35rem;
}

.footer {
  margin-top: 1rem;
  height: 5rem;
  font-size: 0.75rem;
}

.footer a{
  font-size: 0.75rem;
  transform: translateY(0.15rem);
}

@media only screen and ( max-width: 1200px){
  h1{
    font-size: 45px;
  }
  h2{
    font-size: 20px;
  }
  h2 hr {
    width:4rem;
  }
}


@media only screen and ( max-width: 950px){
  h1{
    font-size: 30px;
  }
  h2{
    font-size: 15px;
  }
  .text-container {
    max-width: 70%;
  }
}

