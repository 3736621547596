.intro-container {
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    height:50rem;
    width: 70%;
    text-align: left;
    
}
.intro {
    overflow: hidden;

}
.intro h1{
    animation: slidin 2s ease ;
    margin: auto;
}

.intro p{
    animation:slidin 3s ;
    max-width: 60%;
    
}
#name{
    color: rgb(198, 198, 198);
}

@keyframes slidin{
    0%{
        opacity: 0%;
        transform:translateX(-300%)
    }
}

@media only screen and ( max-width: 950px){
    .intro-container{
        height:35em;
    }
}