.header {
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 4rem;
    padding-left: 1rem;
    z-index: 10;
    background-color: var(--background);
    color:var(--text);
    border-bottom-left-radius: 1rem;
    margin-top: -4rem;
}
.header h2{
    margin: 0;
}
h3 {
    text-align: center;
    
}

span{
    color: var(--highlight);
}

ul {
    display: flex;
    margin-right: 2rem;
    margin-left: auto;
    justify-content: space-between;
    align-items: center; 
    list-style-type: none;
    
}

a {
    position: relative;
    display: inline-block;
    font-size: 1rem;
    color: inherit;
    text-decoration: none;
    overflow: hidden;
}


a::before{
    position: absolute;
    content: attr(data-content);
    color: var(--highlight);
    top: 0;
    left: 0;
    overflow: hidden;
    transition: width 275ms ease;
    width: 0%;
    white-space: nowrap;
}

a:hover::before{
     width: 100%;
}
ul li {
    padding: .25rem;
    margin: 1rem;
}

@media only screen and ( max-width: 950px){
    .header h2 {
        display: none;
    }
    .header{
        height: 2.25rem;
        padding-left: 0rem;
        max-width: 100vw;
    }
    ul {
        padding-left: 0rem;
        width: 100%;
        margin: 1rem 0rem;
    }
    
}
